import React, { ReactElement } from "react";
import MainImg from "./mainImg";
const list = [
    {
        title: "Peaceful Living: ",
        description:
            "Life is an unpredictable journey, and not all surprises are pleasant. With a home warranty, tranquility reigns as you're sheltered from hefty repair bills.",
    },
    {
        title: "Budget Bliss: ",
        description:
            "Home ownership comes with costs, but a home warranty helps you manage unexpected expenses without straining your finances.",
    },
    {
        title: "Ultimate Convenience: ",
        description:
            "No more frantic hunts for dependable contractors in emergencies. A home warranty streamlines repairs, delivering trusted professionals right to your doorstep.",
    },
    {
        title: "Home Value Boost: ",
        description:
            "When it's time to sell, a home warranty can elevate your property's appeal. Prospective buyers appreciate a well-maintained home with the added assurance of a warranty.",
    },
];
export default function WhatAreBenefits(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-44 gap-16 lg:mt-32 mt-16   ">
            <div className="max-w-[520px]  mx-auto lg:mx-0 order-1  lg:mt-10">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] ">
                    Why Home Warranties Matter
                </h2>
                {list.map((point, index) => (
                    <p
                        className="mt-3 first-of-type:mt-5   lg:text-lg text-base lg:leading-8 font-light"
                        key={index}
                    >
                        <span className="font-bold">{point.title}</span>
                        {point.description}
                    </p>
                ))}
            </div>
            <div className="w-[300px] h-[250px] md:h-[457px] md:w-[460px] md:min-w-[460px] relative mx-auto lg:mx-0 order-2  lg:mt-20">
                <MainImg />
            </div>
        </section>
    );
}
