import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 460 458"
        >
            <g clipPath="url(#clip0_1_208)">
                <path
                    fill="#ECF5FF"
                    d="M424.191 431.057H32.523s-58.533-61.884-16.41-115.669c42.121-53.786 86.43-34.122 137.85-96.006 51.419-61.883 74.725-92.749 152.404-90.435 77.676 2.315 220.451 98.497 117.827 302.11h-.003z"
                ></path>
                <path
                    fill="#E29CA1"
                    d="M391.422 95.681s3.513-.299 5.494-2.162c1.982-1.863 5.11-2.865 5.5-3.806.343-.825-.133-2.118-2.369-1.423-2.998.932-3.457 2.524-5.619 2.402-2.162-.124-4.379.438-6.798 2.54-2.35 2.04.913 2.472 3.792 2.45z"
                ></path>
                <path
                    fill="#E29CA1"
                    d="M408.132 89.704c-5.334 1.013-12.326 2.71-14.357 2.37-1.141-.194-2.228-.269-3.288-.197a11.08 11.08 0 00-1.902.297c-4.694 4.263-1.362 7.355-1.268 7.74.831-.133 3.521-.235 4.155-.36 5.491-1.085 6.909-3.272 10.236-4.39 3.327-1.113 13.039-4.599 12.918-5.731-.086-.773-2.912-.407-6.491.271h-.006.003z"
                ></path>
                <path
                    fill="#E8AD80"
                    d="M397.088 93.619c-.603.238-1.207.473-1.81.695-.305.105-.606.213-.911.299-.307.088-.603.155-.913.155-.623.01-1.265-.103-1.916-.144-.647-.05-1.32-.033-1.948.152.647-.06 1.284.008 1.921.097.636.091 1.273.219 1.948.213.698-.02 1.312-.288 1.91-.543a13.11 13.11 0 001.716-.927l.003.003z"
                ></path>
                <path
                    fill="#fff"
                    d="M414.404 89.632s-1.984.324-1.973.551c.011.227.063.415.365.377.302-.04 1.747-.681 1.608-.928z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#F3AFB1"
                    d="M381.657 94.264c-3.117 2.17-6.925 13.82-7.645 14.221-.72.402 5.375 5.787 6.128 6.847 0 0 3.117-14.568 8.608-15.653 5.492-1.085 6.909-3.272 10.236-4.39 3.327-1.114 13.04-4.6 12.918-5.732-.086-.772-2.912-.407-6.491.272h-.005c-5.334 1.013-12.326 2.713-14.355 2.372-1.14-.194-2.228-.268-3.288-.196-2.076.135-4.041.825-6.106 2.262v-.003z"
                ></path>
                <path
                    fill="#fff"
                    d="M400.069 92.423c.925-.197 1.802-.551 2.657-.947.958-.443 1.935-.811 2.923-1.185a13.15 13.15 0 001.813-.822c-.642.105-1.328.227-2.048.363h-.006c-5.333 1.013-12.325 2.71-14.354 2.37-1.14-.195-2.228-.27-3.288-.197-2.051.133-3.992.808-6.029 2.209.161.152.329.299.512.426.606.424 1.326.62 2.062.615.972-.008 1.91-.316 2.837-.57 1.077-.297 2.148-.604 3.228-.889 2.167-.57 4.373-1.038 6.621-1.127 1.035-.041 2.053-.025 3.075-.24l-.003-.006zM411.68 89.757s-1.984.324-1.973.55c.011.228.064.416.365.377.302-.038 1.747-.68 1.608-.927z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M376.97 101.241l-34.009 43.33c-13.984-27.441-40.838-60.143-54.537-52.421-12.159 6.854 11.972 57.074 11.972 57.074s8.968 32.94 47.389 29.554c22.329-1.968 37.94-73.052 37.94-73.052l-8.752-4.488-.003.003z"
                ></path>
                <path
                    fill="#E69A1A"
                    d="M381.04 114.255a67.669 67.669 0 00-5.16 15.462c-.515 2.525-.772 5.135-1.669 7.566-1.013 2.741-2.887 4.978-4.863 7.087-2.098 2.243-4.509 4.17-6.604 6.415-2.085 2.229-3.305 4.831-4.922 7.367-2.773 4.349-6.762 8.64-11.772 10.313-5.48 1.83-11.287-.709-14.384-5.493-1.384-2.137-1.459-4.419-1.556-6.883-.136-3.466-.711-6.86-1.558-10.218-1.437-5.686-3.131-11.342-3.651-17.209-.349-3.92-.266-8.075 1.032-11.835-13.158-17.372-28.235-30.348-37.414-25.174-12.159 6.855 11.971 57.075 11.971 57.075s8.969 32.939 47.39 29.554c20.671-1.822 35.579-62.856 37.682-71.923-1.752 2.481-3.221 5.152-4.52 7.896h-.002z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#8CA9D3"
                    d="M362.057 396.82s-8.431-61.223 8.359-55.182c16.791 6.044 9.085 30.578-8.359 55.182z"
                ></path>
                <path
                    fill="#F2FAFF"
                    d="M362.118 351.835c.856-.67 1.174-1.869 1.561-2.838.452-1.13.958-2.229 1.838-3.095 1.085-1.069 2.464-1.642 3.956-1.85 1.433-.196 2.961-.016 4.354-.468.055-.016.094-.055.146-.074-1.049-.726-2.195-1.382-3.559-1.872-5.412-1.946-8.193 3.109-9.492 10.836.435-.172.853-.374 1.196-.639z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#202234"
                    d="M378.142 352.266c-.067 4.568-.26 9.064-1.636 13.461a52.013 52.013 0 01-2.068 5.465c-.617 1.387-1.425 2.799-2.723 3.657-2.802 1.852-6.098-.044-8.091-2.187-1.226-1.318-2.275-2.979-3.662-4.078.15 14.044 2.098 28.236 2.098 28.236 13.256-18.699 20.815-37.308 16.334-47.834-.227 1.074-.233 2.231-.25 3.28h-.002z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#8CA9D3"
                    d="M360.947 396.227s34.334-60.392 58.021-39.625c23.686 20.766-58.021 39.625-58.021 39.625z"
                ></path>
                <path
                    fill="#F2FAFF"
                    d="M383.774 366.095c.953.136 1.943.208 2.907.188.061 0 .119-.003.18-.003-.089-.022.833-.182.968-.229.958-.335 1.752-.903 2.494-1.584 1.448-1.329 2.655-2.84 4.376-3.843 3.803-2.223 8.454-2.63 12.694-3.474 1.874-.374 5.096-.518 5.868-2.633.161-.437.158-.861.075-1.276-9.724-3.544-20.524 3.452-29.786 12.807.075.014.15.039.224.05v-.003z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#202234"
                    d="M418.119 372.103c-3.183 2.117-6.718 3.291-10.541 3.352-1.992.033-4.049-.138-6.011.308-1.968.445-3.391 1.506-4.711 2.987-2.464 2.763-4.756 5.196-8.241 6.655-3.631 1.52-7.622 1.78-11.517 1.669-1.583-.044-3.105-.24-4.675-.406-1.198-.128-2.782-.358-3.894.254-1.44.792-1.434 3.245-1.182 4.64.18.986.559 1.919 1.085 2.766 16.408-4.421 54.154-16.055 54.824-29.598-1.113 2.926-2.306 5.496-5.135 7.375l-.002-.002z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#9AB6E2"
                    d="M409.261 429.496H112.856l4.252-14.82 9.997-35.101c7.084-24.778 31.696-40.284 57.116-35.955l12.833 2.226c.971.164 1.904.244 2.834.244a17.13 17.13 0 0014.249-7.652c22.022-33.039 71.811-28.947 88.164 7.248l2.186 4.858c3.856 8.572 13.923 12.403 22.501 8.563l12.796-5.73c7.487-3.361 15.827-.85 20.441 5.06a16.703 16.703 0 013.402 7.652l1.901 11.094c.487 2.752 1.539 5.182 3.037 7.206.285.365.567.728.849 1.052a17.161 17.161 0 009.959 5.67c.969.202 2.024.282 3.037.282 3.211 0 6.466-.88 9.419-2.851 6.665-4.449 15.6-3.342 21.263 2.325l8.6 8.599c11.077 11.08 3.23 30.027-12.433 30.027l.002.003z"
                ></path>
                <path
                    fill="#202234"
                    d="M388.795 419.873c-1.829-5.634-8.757-10.975-14.883-9.615-.739.163-1.359-.222-1.608-.914-1.395-3.834.316-9.98-3.88-12.342-3.186-1.791-7.798-.711-10.643 1.268-7.396 5.147-7.219 14.731-8.597 22.729-.164.95-1.575 1.285-2.187.576-1.586-1.83-2.646-4.216-3.864-6.29-1.697-2.887-3.446-5.772-5.48-8.435-1.279-1.672-3.264-4.505-5.536-4.878-2.926-.482-2.718 4.756-2.336 6.5.941 4.299 3.363 8.07 4.481 12.298.174.653-.219 1.459-.914 1.608-1.837.402-3.446-.293-4.949-1.326-1.887-1.296-3.293-2.508-5.591-3.029-3.216-.731-8.434-.717-10.266-4.092-.65-1.198-.462-2.696-.435-4 .059-2.544.31-5.08.327-7.627.031-4.992-1.099-9.74-3.476-14.133-3.826-7.074-9.965-13.178-16.342-17.99-6.322-4.77-13.505-8.48-21.194-10.44-14.648-3.732-31.43-.321-40.755 12.312-4.08 5.523-6.48 12.123-7.523 18.878-1.218 7.89-.263 16.013.684 23.881a151.762 151.762 0 002.485 14.681h161.408c1.633-2.802 2.215-6.104 1.069-9.623l.005.003z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#F2FAFF"
                    d="M261.05 326.741c4.058 2.386 8.985 2.835 13.472 3.976 3.878.985 7.468 2.58 10.543 5.168 4.694 3.954 7.399 9.582 10.535 14.726 3.761 6.174 8.934 12.248 16.095 14.426 7.891 2.403 14.631-3.255 22.207-4.377 6.062-.897 12.251-.055 17.529 3.184 5.315 3.261 9.223 8.4 12.115 13.84 2.818 5.301 5.641 10.603 11.335 13.252 7.346 3.42 15.614 2.536 22.833-.667 2.524-1.121 4.752-2.48 6.95-3.995-4.324-.974-8.957-.321-12.835 2.268a16.795 16.795 0 01-9.419 2.851c-1.013 0-2.065-.08-3.037-.285a17.129 17.129 0 01-9.959-5.67 23.092 23.092 0 01-.849-1.052c-1.498-2.023-2.55-4.454-3.037-7.206l-1.901-11.093a16.662 16.662 0 00-3.402-7.652c-4.614-5.911-12.954-8.422-20.441-5.061l-12.796 5.731c-8.578 3.839-18.645.008-22.501-8.563l-2.186-4.859c-9.469-20.955-30.143-31.126-50.149-29.584 2.219 4.2 4.747 8.197 8.896 10.639l.002.003z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#D7E3F4"
                    d="M383.099 429.496H112.856l4.252-14.82c13.358-11.985 31.817-17.856 50.6-14.617l9.998 1.699 5.383.892c1.174.202 2.348.282 3.482.282a20.25 20.25 0 007.286-1.334 20.45 20.45 0 009.715-7.774 59.407 59.407 0 0111.78-13.117 48.484 48.484 0 015.262-3.928c9.837-6.517 21.13-9.757 32.465-9.878a59.344 59.344 0 0119.552 2.915c15.343 4.859 28.942 15.913 36.512 32.715l2.632 5.75c4.656 10.326 16.757 14.9 27.04 10.285l7.772-3.483 7.49-3.361c3.402-1.539 6.964-2.024 10.363-1.7 8.825.85 16.597 7.409 18.216 16.924l.446 2.55h-.003z"
                ></path>
                <path
                    fill="#8CA9D3"
                    d="M346.455 374.854a5.268 5.268 0 11-10.535 0 5.27 5.27 0 015.267-5.271 5.27 5.27 0 015.268 5.271z"
                ></path>
                <path
                    fill="#C3D1E2"
                    d="M346.261 426.725s-23.76-29.966-12.913-32.032c10.848-2.068 14.463 13.433 12.913 32.032z"
                ></path>
                <path
                    fill="#F2FAFF"
                    d="M332.18 398.78c1.08-.366 2.085-.69 3.228-.789 2.272-.203 4.88.26 6.759 1.611.736.531 1.221 1.185 1.697 1.893-2.134-5.002-5.564-7.746-10.516-6.802-2.278.432-3.017 2.107-2.781 4.463.551.003 1.193-.235 1.616-.376h-.003z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#202234"
                    d="M341.243 416.938c-.989-.216-1.946-.653-2.851-1.199a166.907 166.907 0 007.866 10.986c.327-3.912.418-7.68.266-11.182-1.345 1.547-3.355 1.816-5.284 1.395h.003z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#8CA9D3"
                    d="M346.261 426.725s14.98-40.298 31.507-29.966c16.53 10.332-31.507 29.966-31.507 29.966z"
                ></path>
                <path
                    fill="#F2FAFF"
                    d="M357.975 407.489c1.237-1.099 1.979-2.61 3.28-3.668 1.397-1.138 3.019-1.836 4.677-2.508 1.581-.643 3.419-1.207 4.399-2.711.586-.899.852-2.344.379-3.396-5.81.902-11.144 6.846-15.337 13.349.949-.138 1.868-.415 2.602-1.066z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#202234"
                    d="M366.397 413.729c-2.12.327-5.037.374-6.823 1.678-.899.659-1.478 1.658-2.405 2.306-.894.626-1.954.972-3.006 1.221-1.602.379-3.186.482-4.636.23-2.073 4.358-3.266 7.558-3.266 7.558s16.187-6.622 26.533-14.197c-2.109.579-4.323.883-6.397 1.204z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#8CA9D3"
                    d="M346.261 426.725s-55.27-6.717-41.322-15.501c13.944-8.785 41.322 15.501 41.322 15.501z"
                ></path>
                <path
                    fill="#F2FAFF"
                    d="M309.079 414.327c2.644.208 5.182-.803 7.784-1.068 3.584-.366 6.585.675 9.945 1.697 1.351.409 2.776.758 4.204.833-8.483-4.942-18.705-8.84-25.722-4.756-.064 1.589 1.949 3.148 3.789 3.294z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#202234"
                    d="M330.37 423.602c-1.674-.103-3.36-.211-5.01-.546-1.719-.348-3.39-.891-5.104-1.262-1.063-.23-2.142-.324-3.222-.343 12.694 3.258 29.227 5.274 29.227 5.274s-2.572-2.268-6.502-5.153c-.141.252-.318.49-.545.709-.999.961-2.61 1.132-3.922 1.282-1.633.185-3.283.141-4.922.039z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#000446"
                    d="M437.715 429.365H20.925v.784h416.79v-.784z"
                ></path>
                <path
                    fill="#fff"
                    d="M252.891 329.017a248.794 248.794 0 01-4.235 11.292c-9.173 22.854-20.989 42.045-35.764 58.044a161.758 161.758 0 01-15.055 14.385c-17.114 14.43-36.963 24.781-58.984 30.775-.828.246-1.905.426-3.059.503l-.57.034-.57-.034a14.518 14.518 0 01-3.294-.581c-21.717-5.891-41.555-16.245-58.727-30.697-11.257-9.465-21.125-20.312-29.694-32.668a183.79 183.79 0 01-9.184-14.698v-.011c-6.217-11.035-11.581-23.122-16.162-36.341C3.944 289.671-1.67 246.99.426 198.547l-.01-7.539c0-9.019 7.337-16.367 16.363-16.367h21.629c1.768-.033 48.05-1.489 89.896-49.675l6.934-7.987 6.933 7.987c41.857 48.208 88.172 49.642 90.121 49.686l21.415-.011c9.018 0 16.353 7.35 16.353 16.367l.011 8.09c2.084 47.95-3.54 90.576-17.183 129.922l.003-.003z"
                ></path>
                <path
                    fill="#4462CC"
                    d="M135.24 130.99c-44.754 51.533-94.673 52.798-96.83 52.842H16.776c-3.952 0-7.174 3.217-7.174 7.171v7.94c-2.046 47.302 3.41 88.818 16.674 127.062 11.78 33.947 28.872 60.02 52.28 79.696 16.118 13.568 34.762 23.31 55.45 28.936.36.108.775.172 1.232.202.47-.03.885-.094 1.212-.188 20.701-5.64 39.368-15.368 55.483-28.942 23.394-19.681 40.489-45.757 52.275-79.704 13.266-38.244 18.719-79.76 16.679-126.899l-.008-8.103c0-3.954-3.216-7.171-7.172-7.171h-21.584c-2.209-.044-52.134-1.309-96.885-52.842h.002z"
                ></path>
                <path
                    fill="#202234"
                    d="M197.206 263.224l-71.711 62.158-12.896-30.31-7.894-1.713 19.583 59.854c5.818 8.552 18.102 3.477 24.086-4.98l51.561-87.249-2.732 2.237.003.003z"
                    opacity="0.35"
                ></path>
                <path
                    fill="#fff"
                    d="M195.348 258.138l-71.711 62.158-18.932-26.934-22.373 13.986 29.007 42.629c5.819 8.552 19.542 8.657 25.523.199l63.076-89.189-4.59-2.849z"
                ></path>
                <path
                    fill="#A4B7FC"
                    d="M249.58 183.827c-11.606 82.093-82.121 145.226-167.381 145.226-20.51 0-40.138-3.646-58.323-10.329a292.442 292.442 0 01-7.626-29c-5.887-28.037-8.065-58.019-6.64-90.79v-7.92c0-3.959 3.206-7.187 7.164-7.187h21.64c2.158-.022 52.058-1.321 96.826-52.845 24.679 28.413 50.927 41.549 69.888 47.626 15.397 4.945 25.976 5.219 26.981 5.219h17.471z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#A4B7FC"
                    d="M205.128 178.611c-22.96 66.15-85.809 113.629-159.737 113.629-9.95 0-19.69-.858-29.14-2.513-5.888-28.037-8.066-58.019-6.64-90.79v-7.92c0-3.959 3.204-7.187 7.163-7.187h21.64c2.158-.023 52.058-1.321 96.826-52.845 24.679 28.413 50.927 41.549 69.888 47.626z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#1B2C4C"
                    d="M320.89 416.351s16.536 15.661 11.194 25.373c-5.345 9.712-19.157-13.967-19.611-16.306-.454-2.339-2.566-7.73-2.566-7.73s9.893-6.6 10.925-6.207c1.03.394.058 4.87.058 4.87z"
                ></path>
                <path
                    fill="#EEAB8C"
                    d="M308.96 413.436s5.976 12.004 7.579 11.949c1.605-.053 6.001-2.988 6.001-2.988s-2.99-15.531-4.805-15.365c-1.813.169-8.777 6.404-8.777 6.404h.002z"
                ></path>
                <path
                    fill="#175689"
                    d="M316.118 425.614s-1.716-6.42.377-7.829c2.092-1.409 6.571 4.327 6.571 4.327s10.158 15.448 6.975 17.86c-3.183 2.408-13.923-14.358-13.923-14.358z"
                ></path>
                <path
                    fill="#1B2C4C"
                    d="M317.616 428.458s10.662 20.126 2.494 27.621c-8.169 7.494-13.688-19.358-13.37-21.722.319-2.361.042-8.145.042-8.145s11.481-3.089 12.334-2.389c.852.701-1.5 4.632-1.5 4.632v.003z"
                ></path>
                <path
                    fill="#EEAB8C"
                    d="M307.244 421.883s1.822 13.285 3.361 13.745c1.536.462 6.64-.914 6.64-.914s2.131-15.672.36-16.093c-1.775-.421-10.361 3.259-10.361 3.259v.003z"
                ></path>
                <path
                    fill="#175689"
                    d="M310.134 435.711s.426-6.63 2.859-7.298c2.433-.667 4.841 6.202 4.841 6.202s4.686 17.887.9 19.152c-3.787 1.265-8.603-18.056-8.603-18.056h.003z"
                ></path>
                <path
                    fill="#2A2659"
                    d="M308.476 187.282s41.596 75.834 46.883 106.248c5.287 30.412-33.52 122.71-33.52 122.71h-8.73l-4.633-228.958z"
                ></path>
                <path
                    fill="#202234"
                    d="M319.896 389.079c2.73-7.301 3.732-14.712 3.446-22.483-.163-4.463-.298-8.934.856-13.291 1.01-3.81 2.887-7.232 4.304-10.886 3.377-8.693-1.141-17.441.158-26.325 1.148-7.844 7.813-15.044 5.585-23.275-1.5-5.54-4.506-10.57-6.405-15.971-1.633-4.643-2.776-10.186.191-14.552 2.956-4.349 9.181-6.564 14.493-5.985-14.028-32.516-34.051-69.026-34.051-69.026l4.349 214.892c3.166-3.846 5.405-8.641 7.072-13.098h.002z"
                    opacity="0.35"
                ></path>
                <path
                    fill="#2A2659"
                    d="M269.091 191.216c24.751-4.305 45.948-1.797 45.948-1.797l5.458 231.86s-12.707 5.124-16.97 2.951c-4.263-2.173-46.767-162.861-54.102-199.477-7.337-36.616 19.669-33.537 19.669-33.537h-.003z"
                ></path>
                <path
                    fill="#202234"
                    d="M250.3 228.947a34.128 34.128 0 003.172 2.224c6.718 4.122 14.186 6.885 20.654 11.442 13.549 9.545 15.132 28.008 20.812 42.335 8.265 20.852 8.24 43.092 10.443 65.098 1.085 10.83 2.453 22.494 6.854 32.56 2.134 4.878 5.021 9.441 7.684 14.083l-4.878-207.273s-21.199-2.508-45.95 1.797c0 0-27.004-3.076-19.669 33.537.252 1.252.549 2.664.878 4.195v.002z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M289.535 86.75s11.141 5.803 17.297 17.699c6.156 11.893 34.853 102.63 13.942 118.712-6.519 5.014-21.571 22.137-61.113 7.024-41.068-15.695-15.893-107.852-7.421-122.704 8.473-14.856 18.985-23.222 37.295-20.728v-.003z"
                ></path>
                <path
                    fill="#E5A25E"
                    d="M288.206 95.454c3.887 10.338 7.526 20.758 11.053 31.215a669.918 669.918 0 019.762 31.616c3.073 10.589 5.805 21.281 7.969 32.084 1.157 5.39 1.962 10.844 2.729 16.295l.847 8.225.208 8.272c.401-11.068-1.359-22.045-3.427-32.861-2.109-10.828-4.78-21.545-7.8-32.159a671.814 671.814 0 00-9.942-31.583c-3.551-10.454-7.313-20.841-11.399-31.104z"
                ></path>
                <path
                    fill="#F2A530"
                    d="M313.757 119.454s-10.208 3.262-10.714 3.287c-.507.025 3.811 15.78 7.282 17.441 3.468 1.661 6.352-.163 5.392-9.086-.961-8.923-1.96-11.642-1.96-11.642z"
                ></path>
                <path
                    fill="#F2A530"
                    d="M313.757 119.454s-2.159-.147-5.5.803c-3.341.947-5.212 2.483-5.212 2.483s7.93-.589 10.715-3.286h-.003z"
                ></path>
                <path
                    fill="#D7A28E"
                    d="M265.847 170.054c.562.016 3.136.708 8.689-.216 5.55-.925 8.198-1.814 11.88-1.196 3.681.617 11.351 4.374 10.391 4.645-.961.274-10.139.236-10.139.236s-.039 11.192-5.932 6.691c-5.893-4.502-16.156-5.028-16.156-5.028l1.27-5.132h-.003z"
                ></path>
                <path
                    fill="#FBB140"
                    d="M268.712 177.454l1.415-7.265-31.22-14.692s20.569-21.946 25.67-29.548c5.101-7.602 14.603-32.128-3.731-30.121-18.335 2.007-68.121 46.754-62.096 68.442 5.052 18.178 69.959 13.187 69.959 13.187l.003-.003z"
                ></path>
                <path
                    fill="#F15A29"
                    d="M281.057 98.215c1.437-.69.268-3.267 1.702-3.962.8.338 5.016 2.824 5.816 3.164-.086 1.213-2.813 2.835-2.898 4.048 19.06 34.398 25.603 65.172 40.292 99.814-1.054 13.38-1.292 26.683-3.324 39.98-8.063-11.019-14.573-22.297-20.222-34.736-1.337-34.891-10.369-71.169-20.378-104.435-.933-.579-1.733-1.274-2.665-1.853.099-.755 1.561-1.27 1.677-2.02z"
                ></path>
                <path
                    fill="#202234"
                    d="M285.267 88.467a29.56 29.56 0 00-5.771-.16c-.952.068-1.94.196-2.887.44.185.522.014 1.137-.72 1.35-1.359.393-2.759.654-4.08 1.155-.517.196-1.043.44-1.37.794.108.219-2.22 4.391 4.573 11.658 1.281 1.371 3.822-1.041 1.19-8.61-.606-1.738 9.123 5.997 11.063 6.299 2.887.448 5.766-4.829 2.472-8.065-1.071-1.055-2.344-4.72-3.476-5.576-1.304-.985.634.92-.994.72v-.005z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#D7A28E"
                    d="M283.761 77.955l2.381 9.18s-.817 12.66-14.012 4.122l1.852-15.265 9.777 1.96.002.003z"
                ></path>
                <path
                    fill="#33133F"
                    d="M273.985 75.995l9.776 1.96 1.171 5.72c-.221.11-.443.213-.667.315-1.066.482-2.159.903-3.191 1.45-.811.43-1.611.959-2.176 1.695-.592.775-1.16-2.3-1.129-1.334.05 1.589.98 2.907 1.672 4.277.442.878.741 2.085 1.613 2.652.631.413 1.426.515 2.195.44-2.009 1.506-5.4 1.792-11.119-1.91l1.852-15.265h.003z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#D7A28E"
                    d="M290.183 49.716s8.201 30.8-3.43 32.458c-11.63 1.658-20.626-10.136-20.626-10.136s-4.913-.387-5.708-6.21c-.639-4.692 2.342-4.069 2.342-4.069s-3.191-11.846 5.004-14.861c8.196-3.015 22.418 2.816 22.418 2.816v.002z"
                ></path>
                <path
                    fill="#33133F"
                    d="M263.467 63.63s-1.943-.733-2.209.618c-.263 1.354 1.176 4.607 3.039 5.235 0 0-3.52-2.904-2.607-4.9.916-1.996 1.735-.028 1.774-.955l.003.003z"
                    opacity="0.05"
                ></path>
                <path
                    fill="#EAAD5E"
                    d="M282.87 95.18s-1.76 8.851-5.63 6.534c-3.869-2.317-13.12-9.56-4.733-15.972 0 0 .382 5.053 1.464 6.174 3.347 3.47 8.345-.088 8.345-.088s4.161-2.246 3.853-6.157c0 0 8.359 10.168 3.867 12.339-4.492 2.17-7.166-2.83-7.166-2.83z"
                ></path>
                <path
                    fill="#241D51"
                    d="M270.226 39.085c12.387-.534 6.101-6.677 9.239-4.407 2.494 1.802-.395 3.928-1.693 4.731 1.768-.891 16.316-2.738 19.76 8.176 4.146 13.139-25.709 2.328-25.709 2.328 2.32 4.997-2.806 11.17-5.051 13.557.393 3.015 4.802 5.991 6.773 9.266 2.818 4.679 1.832-1.614 6.485-2.635 4.656-1.022 13.74-.759 13.585 3.499-.152 4.258-3.651 9.598-8.664 9.177-7.31-.611-18.392-3.228-20.227-18.723-5.55-5.462-6.569-24.445 5.502-24.969z"
                ></path>
                <path
                    fill="#D3C971"
                    d="M424.266 19.125h-46.767v63.808h46.767V19.125z"
                ></path>
                <path
                    fill="#E59120"
                    fillRule="evenodd"
                    d="M377.563 21.004h21.916l24.4 24.067H396.54v3.164l27.676 32.311h-46.877l.224-59.542z"
                    clipRule="evenodd"
                    opacity="0.35"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M426.743 43.518h-51.724a.146.146 0 00-.146.146v2.758c0 .08.065.147.146.147h51.724a.147.147 0 00.147-.147v-2.758a.147.147 0 00-.147-.146z"
                ></path>
                <path
                    fill="#BF5A16"
                    d="M428.839 45.126h-55.915v3.051h55.915v-3.05zM428.839 80.718h-55.915v3.05h55.915v-3.05z"
                ></path>
                <path
                    fill="#F9F3DD"
                    d="M399.527 50.89h-15.675v29.841h15.675v-29.84z"
                ></path>
                <path
                    fill="#62C8D8"
                    d="M391.248 52.036h-6.51v8.187h6.51v-8.187zM398.574 52.039h-6.51v8.186h6.51V52.04zM391.248 61.178h-6.51v8.186h6.51v-8.186zM398.574 61.178h-6.51v8.189h6.51v-8.19z"
                ></path>
                <path
                    fill="#BF5A16"
                    d="M398.483 72.304a.946.946 0 11-1.892.002.946.946 0 011.892-.002z"
                ></path>
                <path
                    fill="#fff"
                    d="M384.738 52.039l6.49 6.608V52.04h-6.49zM398.574 61.172h-4.863l4.863 4.953v-4.953zM398.574 57.709l-5.419-5.67h-1.105v7.444l.742.756h5.782v-2.53z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#F9F3DD"
                    d="M417.911 50.979h-12.99v16.48h12.99V50.98z"
                ></path>
                <path
                    fill="#62C8D8"
                    d="M411.049 51.931h-5.394v6.786h5.394V51.93zM417.122 51.934h-5.394v6.785h5.394v-6.785zM411.049 59.506h-5.394v6.785h5.394v-6.785zM417.122 59.508h-5.394v6.786h5.394v-6.786z"
                ></path>
                <path
                    fill="#fff"
                    d="M405.655 51.934l5.381 5.476v-5.476h-5.381zM417.122 59.503h-4.03l4.03 4.103v-4.103zM417.122 56.632l-4.492-4.698h-.916v6.168l.617.626h4.791v-2.096z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#D8D1BA"
                    fillRule="evenodd"
                    d="M383.852 50.89V80.73h14.949l-13.98-22.383-.969-7.456z"
                    clipRule="evenodd"
                    opacity="0.25"
                ></path>
                <path
                    fill="#F9F3DD"
                    d="M395.61 25.177h-11.481v14.567h11.481V25.177z"
                ></path>
                <path
                    fill="#62C8D8"
                    d="M389.548 26.018h-4.769v6h4.769v-6zM394.915 26.018h-4.769v5.997h4.769v-5.997zM389.548 32.715h-4.769v5.997h4.769v-5.997zM394.915 32.715h-4.769v6h4.769v-6z"
                ></path>
                <path
                    fill="#fff"
                    d="M384.779 26.018l4.755 4.842v-4.842h-4.755zM394.915 32.71h-3.562l3.562 3.626V32.71zM394.915 30.174l-3.972-4.156h-.808v5.454l.545.554h4.235v-1.852z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#F9F3DD"
                    d="M417.632 25.177H406.15v14.567h11.482V25.177z"
                ></path>
                <path
                    fill="#62C8D8"
                    d="M411.57 26.018h-4.769v6h4.769v-6zM416.937 26.018h-4.769v5.997h4.769v-5.997zM411.57 32.715h-4.769v5.997h4.769v-5.997zM416.937 32.715h-4.769v6h4.769v-6z"
                ></path>
                <path
                    fill="#fff"
                    d="M406.801 26.018l4.755 4.842v-4.842h-4.755zM416.937 32.71h-3.565l3.565 3.626V32.71zM416.937 30.174l-3.972-4.156h-.808v5.454l.542.554h4.238v-1.852z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M376.855 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M375.955 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M378.983 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M378.083 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M381.112 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M380.212 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M383.24 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M382.341 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.623-.675h-.002z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M385.366 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M384.466 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M387.494 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M386.595 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M389.623 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M388.723 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M391.751 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M390.852 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M393.88 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M392.98 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.623-.675h-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M396.009 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M395.109 36.655H396v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M398.137 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M397.237 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M400.266 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M399.366 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M402.394 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M401.495 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M404.523 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M403.623 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M406.651 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M405.752 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M408.78 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M407.88 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.623-.675h-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M410.908 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M410.009 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M413.037 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M412.137 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.623-.675h-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M415.165 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M414.266 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M417.294 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M416.394 36.655h.891v6.899c0-.05-.265-6.224-.265-6.224l-.623-.675h-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M419.422 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M418.523 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.625-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M421.551 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M420.651 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.626-.675z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M423.679 36.655h-.899v7.286h.899v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M422.78 36.655h.891v6.899c0-.05-.266-6.224-.266-6.224l-.622-.675h-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M425.808 36.655h-.9v7.286h.9v-7.286z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M424.908 36.655h.892v6.899c0-.05-.266-6.224-.266-6.224l-.623-.675h-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M425.982 35.53h-50.201c-.501 0-.908.291-.908.648 0 .358.407.648.908.648h50.201c.501 0 .908-.29.908-.648 0-.357-.407-.647-.908-.647zM403.172 75.363h-.958v7.741h.958v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M402.214 75.363h.947v7.329c0-.053-.283-6.609-.283-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M405.433 75.363h-.957v7.741h.957v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M404.476 75.363h.946v7.329c0-.053-.282-6.609-.282-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M407.692 75.363h-.958v7.741h.958v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M406.734 75.363h.947v7.329c0-.053-.283-6.609-.283-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M409.953 75.363h-.957v7.741h.957v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M408.996 75.363h.946v7.329c0-.053-.282-6.609-.282-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M412.212 75.363h-.955v7.741h.955v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M411.257 75.363h.947v7.329c0-.053-.283-6.609-.283-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M414.473 75.363h-.955v7.741h.955v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M413.518 75.363h.947v7.329c0-.053-.282-6.609-.282-6.609l-.665-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M416.732 75.363h-.955v7.741h.955v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M415.777 75.363h.947v7.329c0-.053-.283-6.609-.283-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M418.993 75.363h-.955v7.741h.955v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M418.038 75.363h.947v7.329c0-.053-.282-6.609-.282-6.609l-.665-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M421.257 75.363h-.957v7.741h.957v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M420.3 75.363h.946v7.329c0-.053-.282-6.609-.282-6.609l-.661-.717-.003-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M423.519 75.363h-.958v7.741h.958v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M422.561 75.363h.947v7.329c0-.053-.283-6.609-.283-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M425.777 75.363h-.957v7.741h.957v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M424.82 75.363h.946v7.329c0-.053-.282-6.609-.282-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M428.039 75.363h-.958v7.741h.958v-7.74z"
                ></path>
                <path
                    fill="#D88E2E"
                    d="M427.081 75.363h.947v7.329c0-.053-.283-6.609-.283-6.609l-.664-.717v-.003z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M428.703 74.173h-26.708c-.265 0-.484.318-.484.714 0 .396.216.715.484.715h26.708c.266 0 .485-.319.485-.715 0-.396-.216-.714-.485-.714zM428.703 82.45h-26.708c-.265 0-.484.32-.484.715 0 .396.216.714.484.714h26.708c.266 0 .485-.318.485-.714 0-.396-.216-.714-.485-.714z"
                ></path>
                <path
                    fill="#BF5A16"
                    d="M430.511 20.988l.656-.991L400.88 0l-30.284 19.997.656.99.654.995 1.503-.994h54.945l1.503.994.654-.994z"
                ></path>
                <path
                    fill="#FFBC4A"
                    d="M400.88 3.02l-23.882 15.772h47.766L400.88 3.02z"
                ></path>
                <path
                    fill="#F0E0B0"
                    fillRule="evenodd"
                    d="M404.667 11.755a3.266 3.266 0 00-3.496-3.015 3.263 3.263 0 103.496 3.015zm-3.015 3.004c-.08.008-.16.01-.24.01a2.77 2.77 0 01-2.763-2.533c-.005-.08-.011-.16-.011-.24a2.77 2.77 0 012.533-2.763c.08-.006.16-.011.241-.011a2.775 2.775 0 012.762 2.533c.005.08.011.16.011.24a2.772 2.772 0 01-2.533 2.763z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#62C8D8"
                    fillRule="evenodd"
                    d="M401.171 12.237v2.522a2.77 2.77 0 01-2.522-2.522h2.522zM404.174 12.237a2.772 2.772 0 01-2.522 2.522v-2.522h2.522zM404.174 11.755h-2.522V9.233a2.775 2.775 0 012.522 2.522zM401.171 9.23v2.522h-2.522a2.77 2.77 0 012.522-2.522z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F0E0B0"
                    fillRule="evenodd"
                    d="M401.652 8.74v6.511a3.436 3.436 0 01-.481 0v-6.51a3.48 3.48 0 01.481 0z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#F0E0B0"
                    fillRule="evenodd"
                    d="M404.675 11.993c0 .08-.003.16-.008.24h-6.511a3.876 3.876 0 010-.48h6.511c.005.08.008.16.008.24z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#E59120"
                    fillRule="evenodd"
                    d="M400.88 3.02l11.899 7.788-12.793-4.914-13.3 9.576 16.688 3.303h-26.686L400.88 3.02z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_208">
                    <path fill="#fff" d="M0 0H460V457.394H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MainImg;
