import React from "react";

function LaundryAppliances() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="77"
            height="77"
            fill="none"
            viewBox="0 0 77 77"
        >
            <circle cx="38.5" cy="38.5" r="38.5" fill="#4462CC"></circle>
            <path
                fill="#fff"
                d="M52.653 28.5v-3.98a2.52 2.52 0 00-2.52-2.52H27.52A2.524 2.524 0 0025 24.52v3.98h27.653zm-3.751-4.485c.662 0 1.234.537 1.234 1.235 0 .68-.554 1.234-1.234 1.234-.68 0-1.235-.554-1.235-1.234 0-.697.572-1.235 1.235-1.235zm-3.981 0c.662 0 1.235.537 1.235 1.235 0 .68-.555 1.234-1.235 1.234s-1.234-.554-1.234-1.234c0-.697.572-1.235 1.234-1.235zm-16.165 0h7.898a1.234 1.234 0 110 2.468h-7.898a1.234 1.234 0 110-2.468zM25.005 29.507v22.474a2.52 2.52 0 002.52 2.519h22.613a2.524 2.524 0 002.52-2.52V29.508H25.005zM38.83 49.688c-4.515 0-8.188-3.673-8.188-8.188 0-4.446 3.627-8.188 8.188-8.188 4.377 0 8.188 3.579 8.188 8.188 0 4.61-3.796 8.188-8.188 8.188z"
            ></path>
            <path
                fill="#fff"
                d="M45.026 37.88c-.571.054-1.139.157-1.69.307-3.074.83-5.91 2.994-9.27 3.046-.816.01-1.625-.1-2.39-.242-.013.17-.027.338-.027.51 0 3.959 3.221 7.18 7.18 7.18 3.855 0 7.18-3.14 7.18-7.18a7.132 7.132 0 00-.983-3.62zm-9.692 6.606a.756.756 0 110-1.511.756.756 0 010 1.511zm3.717 1.995a.756.756 0 110-1.512.756.756 0 010 1.512zm3.117-3.905a.756.756 0 110-1.512.756.756 0 010 1.512z"
            ></path>
        </svg>
    );
}

export default LaundryAppliances;
