import React from "react";

function KitchenAppliances() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="77"
            height="77"
            fill="none"
            viewBox="0 0 77 77"
        >
            <circle cx="38.5" cy="38.5" r="38.5" fill="#4462CC"></circle>
            <path
                fill="#fff"
                d="M51.306 30.812H23V59.04h28.306V30.812zm-26.8.94H49.8a.56.56 0 01.562.561V57.54a.56.56 0 01-.562.56H24.506a.56.56 0 01-.563-.56V32.313c0-.31.252-.56.563-.56zm24.732 1.122h-24.17v24.104h24.17V32.874zM33.7 40.326a.56.56 0 110-1.12h7.362a.56.56 0 110 1.12H33.7zm-5.631-5.02h18.168a.56.56 0 01.562.56v7.113c0 .31-.251.56-.562.56H28.069a.56.56 0 01-.562-.56v-7.112c0-.31.25-.56.562-.56zm17.6 1.127H28.632v5.98H45.67v-5.98zM50.794 27.674v-.276H35.346v.276c0 .57.09 1.076.231 1.431.1.245.22.4.331.4h14.324c.11 0 .23-.155.331-.4.146-.355.23-.86.23-1.431zM54.518 27.383h-2.8c-.101 0-.191.04-.257.105l-.01.006a.405.405 0 00-.105.25V27.774a.39.39 0 00.105.24l.005.01c.066.065.161.105.261.105h2.8c.101 0 .191-.04.262-.105l.005-.005a.37.37 0 00.105-.26.4.4 0 00-.1-.26l-.01-.005a.377.377 0 00-.261-.11zM33.916 28.5v-6.547H23.663V28.5c0 .275.125.525.336.71l.03.025c.23.19.547.31.888.31h7.74c.345 0 .662-.12.887-.31a.94.94 0 00.372-.735zM28.16 19.56a.56.56 0 111.119 0v.786h4.19a.56.56 0 01.562.56c0 .311-.25.567-.562.567h-9.5a.565.565 0 01-.563-.566c0-.31.251-.56.563-.56h4.19v-.786z"
            ></path>
        </svg>
    );
}

export default KitchenAppliances;
