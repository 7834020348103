import React from "react";

function Plumbing() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="77"
            height="77"
            fill="none"
            viewBox="0 0 77 77"
        >
            <circle cx="38.5" cy="38.5" r="38.5" fill="#4462CC"></circle>
            <path
                fill="#fff"
                d="M21.877 20H20v10.394h1.877V20zM21.877 46.046H20V56.44h1.877V46.046zM56.286 46.046H54.41V56.44h1.877V46.046zM29.748 37.274h-1.015v1.892h1.015v-1.892zM22.924 54.029h30.439v-5.576h-8.919a.523.523 0 01-.523-.523v-1.786H38.37v1.786c0 .29-.234.523-.523.523H22.924v5.576zM32.672 35.355h-1.877v5.73h1.877v-5.73zM36.346 22.41H22.924v5.576h13.422c1.117 0 2.024.911 2.024 2.031v.28h5.551c0-4.78-3.611-7.886-7.575-7.886zM46.318 43.206H35.97v1.891h10.35v-1.891zM37.323 37.274h-3.6v1.892h3.6v-1.892zM46.318 31.343H35.97v1.891h10.35v-1.891zM43.921 34.28H38.37v7.883h5.551V34.28z"
            ></path>
        </svg>
    );
}

export default Plumbing;
