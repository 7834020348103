import React, { ReactElement } from "react";

import Plumbing from "./icons/plumbing";
import ElectricalSystems from "./icons/electricalSystems";
import HeatingCooling from "./icons/heatingCooling";
import KitchenAppliances from "./icons/kitchenAppliances";
import LaundryAppliances from "./icons/laundryAppliances";
import FeatureList from "@components/shared/featureList";

const list = [
    {
        title: "Plumbing",
        icon: <Plumbing />,
    },
    {
        title: "Electrical systems",
        icon: <ElectricalSystems />,
    },
    {
        title: "Heating and cooling systems",
        icon: <HeatingCooling />,
    },
    {
        title: "Kitchen appliances (e.g., refrigerator, oven, dishwasher)",
        icon: <KitchenAppliances />,
    },
    {
        title: "Laundry appliances (e.g., washer, dryer)",
        icon: <LaundryAppliances />,
    },
];

export default function LiesBeneath(): ReactElement {
    return (
        <FeatureList
            featureList={list}
            mainTitle="What Lies Beneath the Shield?"
            mainDescription="Home warranties typically encompass vital systems and appliances, including:"
            colors={{
                bgColor: "#F4F9FF",
            }}
            classes={{
                mainSectionClasses:
                    "lg:pt-[67px] pt-[37px] lg:pb-[70px] pb-[35px]",
                mainTitleClasses:
                    "font-bold lg:text-3xl text-2xl lg:leading-[45px] text-center ",
                mainDescriptionClasses:
                    "lg:text-lg text-base lg:leading-8 font-light text-center mt-1",
                innerSectionClasses:
                    "flex     flex-wrap  justify-center gap-[44px] lg:mt-10 mt-5  ",
                oneBoxClasses: "text-center  w-full sm:w-[193px] ",
                iconClasses: "children:mx-auto",
                titleClasses:
                    " text-base lg:leading-8 font-bold lg:mt-[37px] mt-5",
            }}
        />
    );
}
