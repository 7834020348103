import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 391 355"
        >
            <g clipPath="url(#clip0_0_601)">
                <path
                    fill="#D9E7FF"
                    d="M81.891 144.708S64.2 60.102 137.492 37.468c63.519-19.616 61.062-44.873 111.607-35.421 50.545 9.45 50.058 65.238 100.013 89.07 41.051 19.583 81.089 166.08-31.657 174.007C247.186 270.066 81.893 144.71 81.893 144.71l-.002-.002z"
                ></path>
                <path
                    fill="#E8EEFA"
                    d="M66.888 235.16c-.695-3.99-7.33-6.573-14.823-5.767-.5.054-.99.124-1.47.203-3.662-1.502-8.564-2.158-13.776-1.597-10.122 1.087-17.566 6.339-16.628 11.727.925 5.311 9.646 8.772 19.583 7.833 2.2 2.789 7.75 4.398 13.878 3.741 7.492-.804 13.001-4.691 12.308-8.681-.162-.924-.653-1.768-1.388-2.516 1.73-1.46 2.62-3.191 2.316-4.943z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M37.643 217.189c10.286-.388 17.61-12.97 17.199-22.302-.153-3.486-5.61-3.506-5.455 0 .151 3.426 2.794 6.379.87 9.269-2.011 3.022-4.426 9.911-12.437 9.481-3.496-.189-3.693 3.684-.177 3.552z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M35.236 244.905c-1.158 0-2.104-1.158-2.104-2.574V206.73c0-1.416.947-2.574 2.104-2.574 1.158 0 2.103 1.158 2.103 2.574v35.601c0 1.416-.947 2.574-2.103 2.574z"
                ></path>
                <path
                    fill="#879FD4"
                    d="M34.976 186.625c12.142-1.23 21.108-10.832 20.027-21.447-1.081-10.615-11.8-18.223-23.942-16.994-12.142 1.23-21.108 10.832-20.027 21.447 1.081 10.615 11.8 18.223 23.942 16.994z"
                ></path>
                <path
                    fill="#879FD4"
                    d="M41.343 207.603c12.142-1.23 21.108-10.832 20.027-21.447-1.081-10.615-11.8-18.224-23.942-16.994-12.142 1.23-21.108 10.832-20.027 21.447 1.081 10.615 11.8 18.223 23.942 16.994z"
                ></path>
                <path
                    fill="#879FD4"
                    d="M36.914 181.352c0 7.909-7.333 14.319-16.377 14.319-9.045 0-16.378-6.412-16.378-14.319 0-7.907 10.438-14.059 19.483-14.059s13.272 6.151 13.272 14.059zM49.324 159.798c5.404-.129 11.725 4.635 11.873 10.782.148 6.146-4.666 12.453-10.07 12.582-5.405.128-9.904-4.748-10.052-10.895-.148-6.147 2.845-12.34 8.249-12.469z"
                ></path>
                <path
                    fill="#91ABE3"
                    d="M46.184 168.038c1.284-5.098-3.89-10.788-11.555-12.708-7.666-1.92-14.921.657-16.205 5.755-1.284 5.098 3.889 10.787 11.555 12.708 7.665 1.92 14.92-.657 16.205-5.755zM28.356 182.991c.554-3.029-2.687-6.156-7.24-6.985-4.552-.829-8.693.954-9.247 3.982-.555 3.028 2.686 6.155 7.239 6.984 4.553.83 8.693-.953 9.248-3.981z"
                ></path>
                <path
                    fill="#D9E7FF"
                    d="M189.545 338.709l86.163 2.798a60.676 60.676 0 0033.222-8.631l68.427-41.112c6.923-4.16 6.741-14.23-.328-18.137L312.032 237.7 189.545 338.707v.002z"
                ></path>
                <path
                    fill="#ADC1F0"
                    d="M64.2 263.144V158.196l41.272-24.225 17.527 2.3 66.546 65.521V335.26l-24.012-13.825v.049L64.2 263.144z"
                ></path>
                <path
                    fill="#D9E7FF"
                    d="M189.545 201.919l16.952-9.758v-.353l1.437-.475 69.071-19.346 3.531-3.43 39.078 8.459-1.541 84.369-98.669 56.808v-.122l-29.859 17.189V201.919z"
                ></path>
                <path
                    fill="#3B57B3"
                    d="M244.674 67.285l74.94 104.702-128.712 72.535-73.622-108.297 127.394-68.94z"
                ></path>
                <path
                    fill="#2E4DA3"
                    d="M117.28 136.225l-72.62 30.402 32.42-21.132 119.745-61.684 47.849-16.526-127.394 68.94z"
                ></path>
                <path
                    fill="#fff"
                    d="M319.614 171.987v5.029l-128.807 72.033.071-4.524 128.736-72.538z"
                ></path>
                <path
                    fill="#fff"
                    d="M190.807 249.049L117.28 142.706v-6.481l73.622 108.297-.095 4.527zM117.28 142.706l-72.62 29.281v-5.36l72.62-30.402v6.481z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M94.186 240.891l-22.24-12.805v-35.819l22.24 12.805v35.819z"
                ></path>
                <path
                    fill="#fff"
                    d="M94.186 240.891v-35.818l-18.357-10.569v35.818l18.357 10.569z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M94.184 235.835v-27.04l-13.857-7.98v27.042l13.857 7.978zM171.11 281.295l-22.24-12.806v-35.818l22.24 12.804v35.82z"
                ></path>
                <path
                    fill="#fff"
                    d="M171.11 281.295v-35.819l-18.356-10.569v35.818l18.356 10.57z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M171.108 276.238v-27.041l-13.857-7.978v27.039l13.857 7.98z"
                ></path>
                <path
                    fill="#223B82"
                    d="M222.432 280.101l22.242-12.804v-35.82l-22.242 12.806v35.818z"
                ></path>
                <path
                    fill="#fff"
                    d="M222.432 280.101v-35.818l18.357-10.569v35.818l-18.357 10.569z"
                ></path>
                <path
                    fill="#223B82"
                    d="M222.434 275.044v-27.039l13.859-7.978v27.039l-13.859 7.978zM271.358 252.87l22.24-12.804v-35.82l-22.24 12.806v35.818z"
                ></path>
                <path
                    fill="#fff"
                    d="M271.358 252.87v-35.818l18.356-10.568v35.818l-18.356 10.568z"
                ></path>
                <path
                    fill="#223B82"
                    d="M271.358 247.813v-27.039l13.858-7.978v27.039l-13.858 7.978z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M189.545 338.709v-14.235L64.133 252.347v14.212l125.412 72.15z"
                ></path>
                <path
                    fill="#223B82"
                    d="M189.545 338.709v-14.235l128.727-74.034-.819 14.683-127.908 73.586z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M106.208 208.75v70.63l25.851 14.881v-.057l6.162 3.549v-70.631l-32.013-18.372z"
                ></path>
                <path
                    fill="#fff"
                    d="M115.052 284.471l17.007 9.79v-.057l6.162 3.549v-70.631l-23.169-13.297v70.646z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M135.904 249.049l-15.318-8.819v-16.92l15.318 8.82v16.919zM117.637 255.634c-.609-.334-1.105-1.104-1.105-1.711v-4.862c0-.607.498-.829 1.105-.493.609.334 1.105 1.104 1.105 1.711v4.861c0 .607-.498.829-1.105.494zM68.648 285.529v13.412l63.006 36.275 26.587-15.307v-13.413l-89.593-20.967z"
                ></path>
                <path
                    fill="#3457B8"
                    d="M158.241 306.496l-63.006-36.274-26.587 15.307 63.006 36.274 26.587-15.307z"
                ></path>
                <path
                    fill="#D9E7FF"
                    d="M131.654 335.216l57.891 3.493-31.304-18.8-26.587 15.307z"
                ></path>
                <path
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.77"
                    d="M64.133 165.672v86.675"
                ></path>
                <path
                    fill="#6F8BE8"
                    d="M195.213 180.737l66.446-36.554c2.622-1.443 1.991-4.28-1.403-6.306-3.394-2.027-8.316-2.503-10.939-1.06l-66.446 36.554c-2.623 1.443-1.992 4.28 1.402 6.306 3.395 2.026 8.316 2.503 10.94 1.06z"
                ></path>
                <path
                    fill="#032F45"
                    d="M201.498 184.976l66.446-36.554c2.622-1.443 1.991-4.28-1.403-6.306-3.394-2.027-8.316-2.503-10.939-1.06l-66.446 36.554c-2.623 1.443-1.992 4.28 1.402 6.306 3.395 2.027 8.316 2.503 10.94 1.06z"
                ></path>
                <path
                    fill="#F0B037"
                    d="M203.484 162.732c-1.645-.58-12.347-7.502-13.575-8.981-4.379-3.986-6.838-10.457-6.433-18.737.889-18.169 15.207-38.136 31.979-44.597 7.817-3.011 14.111-3.457 19.651-.337 3.084 1.546 11.429 6.286 11.429 6.286 1.539 1.487-1.465 2.155-.478 4.254 2.171 3.782 3.282 8.659 2.999 14.419-.889 18.169-15.207 38.136-31.979 44.597-7.063 2.721-8.648 5.492-13.593 3.098v-.002z"
                ></path>
                <path
                    fill="#fff"
                    d="M246.256 143.751c12.149-18.173 12.293-39.357.321-47.316-11.972-7.96-31.527.32-43.676 18.494-12.15 18.173-12.293 39.357-.321 47.316 11.972 7.959 31.526-.322 43.676-18.494z"
                ></path>
                <path
                    fill="#FFD482"
                    d="M247.485 144.466c12.149-18.173 12.293-39.357.321-47.316-11.972-7.96-31.527.321-43.676 18.494-12.15 18.173-12.293 39.357-.321 47.316 11.972 7.959 31.526-.321 43.676-18.494z"
                ></path>
                <path
                    fill="#fff"
                    d="M241.569 140.447c8.494-12.704 8.594-27.514.224-33.078-8.37-5.564-22.04.224-30.533 12.929-8.494 12.704-8.594 27.514-.224 33.078 8.369 5.564 22.04-.224 30.533-12.929z"
                ></path>
                <path
                    fill="#F2C97B"
                    d="M246.028 121.371c-.73 13.17-10.968 27.371-22.87 31.717-4.744 1.733-9.036 1.637-12.435.061 3.632 2.585 8.674 3.139 14.367 1.06 11.902-4.346 22.14-18.545 22.87-31.718.438-7.92-2.663-13.663-7.794-16.039 3.959 2.818 6.243 8.049 5.862 14.919z"
                ></path>
                <path
                    fill="#FFD482"
                    d="M227.763 117.018c2.265-.587 4.93-.592 6.69.402l-2.587 4.804c-1.918-.741-3.589-.882-5.44-.18-2.157.817-3.149 2.08-3.205 3.88-.126 4.025 10.05.095 9.831 7.13-.119 3.831-2.437 7.336-6.083 9.45l-.126 4.026-3.738 1.416.123-3.948c-2.456.582-4.19.002-5.642-1.031l2.953-4.363c1.347.765 2.522.977 4.139.363 1.851-.7 2.876-1.823 2.93-3.581.116-3.714-10.066.452-9.835-7.014.117-3.752 2.25-7.418 6.246-9.781l.125-4.064 3.738-1.416-.122 3.909.003-.002z"
                ></path>
                <path
                    stroke="#FFD482"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4.24"
                    d="M201.317 116.497l-9.635-5.475M196.597 125.518l-9.341-5.378M193.789 135.063l-9.34-5.377M193.138 144.708l-8.615-4.958M195.383 154.216l-8.63-5.236M207.716 107.905l-9.301-5.44M215.236 101.095l-9.302-5.441M223.847 96.068l-8.577-5.016M233.674 93.566l-8.841-4.902"
                ></path>
                <path
                    fill="#FFFCFA"
                    d="M279.332 73.683c.131-8.58-3.408-17.083-8.77-20.169a8.622 8.622 0 00-2.535-.987c-1.576-7.698-5.362-14.369-10.414-17.279-5.164-2.974-10.378-1.282-13.945 3.71-.999-1.175-2.107-2.14-3.308-2.831-5.361-3.087-11.145 3.454-7.783 10.638l46.755 26.918zM154.473 87.264c.088-5.84-2.32-11.628-5.971-13.729a5.86 5.86 0 00-1.726-.671c-1.073-5.24-3.649-9.781-7.087-11.762-3.515-2.024-7.064-.871-9.492 2.525-.679-.8-1.434-1.456-2.252-1.928-3.649-2.1-7.585 2.352-5.297 7.243l31.825 18.322zM335.863 139.372c.089-5.84-2.319-11.627-5.97-13.728a5.873 5.873 0 00-1.726-.672c-1.073-5.239-3.649-9.78-7.089-11.761-3.515-2.025-7.064-.872-9.492 2.525-.679-.801-1.434-1.457-2.252-1.928-3.649-2.101-7.586 2.352-5.297 7.242l31.825 18.322h.001z"
                ></path>
                <path
                    fill="#20377A"
                    d="M131.654 335.216v-13.413l26.587-15.307v13.413l-26.587 15.307z"
                ></path>
                <path
                    fill="#E8EEFA"
                    d="M.005 335.423c.255-4.836 8.875-8.852 18.154-8.365 8.057.424 17.507 3.774 18.933 7.803 6.243-.524 12.803.438 17.367 2.863 7.328 3.89 6.743 10.074-1.306 13.811-8.048 3.737-20.512 3.614-27.84-.276-3.506-1.862-5.196-4.25-5.127-6.642-.025 0-.05 0-.076-.004-9.281-.488-20.362-4.354-20.107-9.19h.002z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M25.257 332.016l3.272.794c2.45-.151 3.697-.49 4.423.398l.206.253c.726.888.248 1.789-1.063 1.999l-5.98 1.894c-1.31.21-2.682-.534-3.254-1.611 0 0-.337-.656.772-2.462.633-1.029.575-1.097 1.624-1.267v.002z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M11.524 308.285l-8.007 19.507c-.925 2.398 2.171 2.701 3.088 1.382l10.917-24.691-6 3.804.002-.002zM23.589 289.936l.177 43.591c-.03 2.725 2.879 1.947 3.263.305l2.853-40.49-6.293-3.406z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M33.365 277.617l-5.642 52.424s-2.23 3.094-4.858-.257c-1.027-8.305-1.553-15.998.114-21.011l.124-22.591-.61-9.139 10.872.572v.002z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M23.19 279.85l-3.415 23.533a6.475 6.475 0 01-.597 1.928l-9.025 18.416s-3.917 2.134-5.52-2.275c2.526-10.273 5.434-16.472 8.673-19.172a3.54 3.54 0 00.3-1.124l2.356-24.679 7.227 3.374v-.001z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M27.322 294.518l-9.072-4.146a5.333 5.333 0 01-3.044-5.752l.869-5.045 15.43 7.644-4.183 7.3v-.001z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M29.202 252.856c-.085 3.024-2.287 3.468-4.54 2.086-2.253-1.382-3.704-4.066-3.62-7.09.083-3.023 1.979-4.354 4.232-2.972 2.253 1.382 4.011 4.953 3.928 7.976z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M31.947 251.199c-.085 3.024-2.848 4.82-5.102 3.438-2.253-1.382-4.011-4.954-3.928-7.977.085-3.023 1.98-4.354 4.233-2.972 2.253 1.382 4.882 4.488 4.797 7.513v-.002z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M23.613 261.806l-.274-.1c-.925-.337-.845-1.623-.4-2.834l2.036-6.808c.444-1.21 1.564-1.924 2.49-1.587l.273.1c.925.338 1.316 1.604.872 2.815l-1.673 6.829c-.444 1.21-2.4 1.922-3.323 1.585h-.001z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M22.244 253.077s.573 1.5 3.6 1.051c2.884-.429 1.462-4.611 4.06-5.747 2.857-1.249 2.238-4.517.7-5.626-1.54-1.109-5.014-1.004-7.122.653-2.109 1.656-4.534 3.084-1.238 9.67v-.001z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M29.316 250.984a1.333 1.333 0 01-1.372 1.294 1.332 1.332 0 11.075-2.662c.738.02 1.318.632 1.297 1.368z"
                ></path>
                <path
                    fill="#A7BBE6"
                    d="M16.677 265.136L14.582 281.9s-1.888 1.916 7.118 5.114c7.268 2.583 12.73 1.962 13.214 1.131l-.435-18.569 6.451 4.415s-1.139-9.05-9.71-14.488c-1.56-.992-3.885-2.202-5.788-3.15-2.826-1.409-3.07-1.608-5.827-.07-.082.046-2.73 6.127-2.928 8.855v-.002zM22.789 250.957c1.551-.213 2.608-1.844 2.36-3.642-.248-1.798-1.708-3.082-3.26-2.869-1.551.213-2.608 1.843-2.36 3.641.248 1.798 1.708 3.083 3.26 2.87z"
                ></path>
                <path
                    fill="#fff"
                    d="M28.007 257.674s-2.62-2.281-4.692-2.04c0 0-.024 2.745 4.692 2.04z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M16.573 261.686a1.996 1.996 0 011.029 2.626l-.358.811a2.008 2.008 0 01-2.633 1.026 1.997 1.997 0 01-1.03-2.627l.358-.81a2.008 2.008 0 012.634-1.026z"
                ></path>
                <path
                    fill="#3457B8"
                    d="M32.983 290.093l-2.749 7.948 12.57 4.323 2.748-7.948-12.569-4.323z"
                ></path>
                <path
                    fill="#F0A548"
                    d="M40.432 258.138l-9.259 14.076c-1.325 2.359-5.918 3.152-8.288 1.435l-7.756-6.812 2.592-3.749 6.5 4.522s2.92-9.165 5.561-10.481c2.64-1.316 10.65 1.009 10.65 1.009z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M28.998 338.126l3.38-.574c2.23-1.126 3.259-1.943 4.298-1.404l.294.153c1.039.539.954 1.574-.185 2.296l-4.825 4.175c-1.139.724-2.726.58-3.693-.197 0 0-.582-.478-.274-2.613.175-1.218.094-1.258 1.007-1.836h-.002zM43.158 343.771l3.369.632c2.482-.285 3.732-.695 4.518.17l.223.245c.785.865.348 1.806-.973 2.09l-5.977 2.245c-1.322.285-2.758-.401-3.396-1.464 0 0-.378-.649.653-2.545.588-1.08.525-1.146 1.583-1.373z"
                ></path>
                <path
                    fill="#C6D2F2"
                    d="M45.505 344.6c0 1.697-4.141 1.697-4.141 0v-5.518h4.14v5.518z"
                ></path>
                <path
                    fill="#3457B8"
                    d="M46.027 341.113c-.522 2.151-4.828 1.995-5.666.604-.852-6.525-3.394-16.021-.444-22.125l-1.9-35.477 10.6.137c.074 19.036-.653 38.002-2.59 56.863v-.002z"
                ></path>
                <path
                    fill="#C6D2F2"
                    d="M32.226 338.661c-.027 1.54-4.165 1.72-4.138.18l.089-5.004 4.137-.18-.088 5.004z"
                ></path>
                <path
                    fill="#3457B8"
                    d="M32.94 336.408c-1.578 1.767-4.862 1.183-5.54-.014-1.396-7.902-2.25-16.841.012-22.668l.013-27.148 10.465 1.086-4.95 48.744z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M41.416 256.448c1.916-.567 2.657-3.76 1.653-7.133-1.004-3.372-3.371-5.646-5.288-5.078-1.917.567-2.657 3.761-1.653 7.133 1.003 3.372 3.37 5.646 5.288 5.078z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M46.363 251.822c-.364 3.025-3.304 4.571-5.436 2.977-2.132-1.594-3.566-5.338-3.202-8.364.364-3.026 2.39-4.187 4.522-2.593 2.132 1.594 4.482 4.953 4.116 7.978v.002z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M38.077 261.304l-.323-.144c-1.09-.485-1.032-1.706-.546-2.793l2.189-6.191a2.178 2.178 0 012.865-1.094l.323.145a2.166 2.166 0 011.097 2.857l-1.765 6.272c-.487 1.087-2.75 1.433-3.84.948z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M36.455 252.812s.437 1.56 3.515 1.385c2.933-.166 1.894-4.49 4.605-5.392 2.982-.99 2.662-4.326 1.222-5.58-1.44-1.254-4.936-1.469-7.205 0-2.268 1.466-4.832 2.677-2.137 9.587z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M44.587 250.771a1.343 1.343 0 00-1.334-1.353 1.344 1.344 0 00-1.356 1.33c-.007.74.59 1.346 1.333 1.352a1.342 1.342 0 001.357-1.329z"
                ></path>
                <path
                    fill="#FFBA63"
                    d="M26.03 286.434c.613-6.599.506-11.417.613-18.522-.614-17.976 13.77-11.03 14.614-10.598 8.927 4.587 9.53 5.374 9.252 10.637.041 8.763.412 19.277.226 23.589-1.84 6.06-22.664-.768-24.704-5.104l-.002-.002z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M16.4 250.189c-1.02 3.023-1.339 11.23.644 11.32 6.569.297 5.147-6.011 6.168-9.034 1.02-3.024.32-5.986-1.56-6.619-1.88-.63-4.234 1.309-5.254 4.333h.001zM7.472 327.772l-.976 2.793c-.075 2.155.117 3.274-.726 3.833l-.24.16c-.843.559-1.593.064-1.668-1.099l-1.16-5.387c-.075-1.163.697-2.301 1.692-2.71 0 0 .607-.239 2.1.884.852.641.917.595.976 1.526h.002z"
                ></path>
                <path
                    fill="#fff"
                    d="M44.153 258.718s-5.761-3.886-8.974-3.513c0 0 1.66 4.607 8.974 3.513z"
                ></path>
                <path
                    fill="#FFBA63"
                    d="M49.717 262.812s2.525 3.503 2.755 13.841l-6.279 1.733-.935-11.054 4.457-4.52h.002z"
                ></path>
                <path
                    fill="#F0A548"
                    d="M50.734 291.538l-2.11-21.947-4.038-1.011-1.596 25.19s6.708 1.436 7.744-2.233v.001z"
                ></path>
                <path
                    fill="#ED5353"
                    d="M59.367 303.367a1.574 1.574 0 00.854-2.055l-.975-2.348a1.582 1.582 0 00-2.06-.852 1.575 1.575 0 00-.854 2.056l.974 2.348a1.58 1.58 0 002.06.851z"
                ></path>
                <path
                    fill="#FFBA63"
                    d="M52.126 272.016l1.303 12.099 6.166 14.411-3.525 1.38-7.998-12.775a6.418 6.418 0 01-.858-2.17l-3.779-19.26 8.691 6.315z"
                ></path>
                <path
                    fill="#2B4796"
                    d="M36.628 300.095l-3.11-1.523c-.893-.276-1.587.999-1.612 1.931-.109 4.058-.388 13.403-.677 13.908-.33.58-1.675 16.442.005 22.98.641-.159 1.247-.469 1.706-.983l3.687-36.315.001.002z"
                ></path>
                <path
                    stroke="#142B6B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.67"
                    d="M319.993 179.212v13.661"
                ></path>
                <path
                    fill="#E8EEFA"
                    d="M328.342 296.139c11.47 0 20.768-4.978 20.768-11.119s-9.298-11.119-20.768-11.119-20.768 4.978-20.768 11.119 9.298 11.119 20.768 11.119z"
                ></path>
                <path
                    fill="#142B6B"
                    d="M330.364 255.839a2.018 2.018 0 00-2.021-2.016 2.018 2.018 0 00-2.021 2.016v28.455c0 1.114.905 2.016 2.021 2.016a2.018 2.018 0 002.021-2.016v-28.455z"
                ></path>
                <path
                    fill="#F0B037"
                    d="M346.675 228.238c.144-1.08.219-2.187.219-3.315 0-5.146-1.561-9.869-4.164-13.57a19.9 19.9 0 00.554-4.67c0-9.566-6.69-17.32-14.942-17.32-8.252 0-14.942 7.754-14.942 17.32 0 1.619.196 3.184.554 4.67-2.601 3.701-4.164 8.424-4.164 13.57 0 1.128.076 2.235.219 3.315-4.56 3.882-7.378 9.201-7.378 15.073 0 11.877 11.51 21.503 25.709 21.503s25.71-9.628 25.71-21.503c0-5.872-2.818-11.191-7.379-15.073h.004z"
                ></path>
                <path
                    fill="#E0A533"
                    d="M346.675 228.238c.144-1.08.219-2.187.219-3.315 0-5.146-1.562-9.869-4.164-13.571.359-1.487.554-3.052.554-4.669 0-7.214-3.805-13.396-9.216-16.001-5.728 35.606 10.859 51.788-14.077 72.967a30.211 30.211 0 008.351 1.167c14.199 0 25.71-9.628 25.71-21.503 0-5.872-2.818-11.192-7.379-15.073l.002-.002z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_0_601">
                    <path fill="#fff" d="M0 0H391V354.262H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MainImg;
