import React, { ReactElement } from "react";
import MainImg from "./mainImg";
import SharedButton from "../sharedButton";
export default function LeaveHome(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-40 gap-12 lg:mt-44 mt-20   lg:mb-[100px] mb-[50px]">
            <div className="max-w-[558px]  mx-auto lg:mx-0 order-2 lg:order-1   lg:mt-16">
                <p className="lg:text-lg text-base lg:leading-8 font-light">
                    Don't leave your home's well-being to fate. Take the first
                    step in preserving your home and peace of mind. Simply
                    complete our swift and hassle-free form to request your
                    complimentary home warranty quote. No commitments, just
                    friendly assistance every step of the way. Your home is your
                    sanctuary. Let WarrantyFor.House be your steadfast partner
                    in its safety and security. Begin your journey today!
                </p>
                <div className="mt-7 text-center lg:text-left">
                    <SharedButton>
                        Get Your Free Home Warranty Quote Today!
                    </SharedButton>
                </div>
            </div>
            <div className="w-[300px] h-[230px] md:h-[457px] md:w-[391px] md:min-w-[354px] relative mx-auto lg:mx-0 order-1 lg:order-2 ">
                <MainImg />
            </div>
        </section>
    );
}
