import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/nextautowarrantyVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";
export default function MainBanner(): ReactElement {
    const { visitDetails, domain } = useDomainContext();
    const router = useRouter();

    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles["main-banner"]}>
                    <Form
                        classes={{
                            formClassName: styles["form"],
                            stepTitleClassName: styles["step-title"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formStepInnerClassName: styles["steps-inner"],
                            stepClassName: styles["step"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                                checkbox: styles["checkbox-field"],
                                all: styles["field"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                            fieldNoteClassName: styles["field-note"],
                            fieldErrorMessageClassName:
                                styles["field-error-message"],
                            sellingPointClassName: styles["selling-point"],
                        }}
                        colors={{
                            primaryColor: colors.gorseYellowOrange,
                            progressBar: "#D9D9D9",
                        }}
                        showProgress={true}
                        goToThankYouPage={false}
                        sellingPoint={
                            visitDetails?.region
                                ? `${visitDetails?.region}'s Best Extended Home Warranty`
                                : ""
                        }
                        sellingPointsSteps="FIRST_STEP"
                        dynamicThankYouFields={["firstName"]}
                    />
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
